@import 'config';

.project {
  display: flex;
  flex-direction: column;
  gap: 3rem;

  .description, .technologies {
    margin: 0 auto;
    width: 100%;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  h4 {
    font-family: $secondary-font;
    margin: 0.25rem 0;
  }

  .screenshots {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    gap: 0.5rem;

    img {
      max-width: calc(700px/4 - 1.5rem);
      border: 1px solid darkgray;
      border-radius: 5px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  h3 {
    font-family: $secondary-font;
    font-size: 1.8rem;
    font-weight: normal;
  }

  a {
    color: $dark;
    &:hover {
      color: $blue;
    }
  }

  p a {
    text-decoration: underline;
  }
}

@media screen and (max-width: 767px) {
  .project {
    margin: 1rem 2rem;

    .description {
      text-align: justify;
    }
    h4 {
      padding-top: 1rem;
      text-align: center;
      font-size: 1.2rem;
    }
    .screenshots {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
    }
    .technologies {
      margin-bottom: 2rem;
      h3 {
        text-align: center;
      }
      .tech-list {
        justify-content: center;
      }
    }
    em {
      display: block;
      text-align: center;
      border-radius: 1rem;
      background-color: #0F6292;
      color: #fff;
      padding: 0.5rem;
    }
  }
}