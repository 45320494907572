@import 'config';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: $background;
  font-family: $primary-font;
}

header, main {
  margin: 0 auto;
  max-width: 1250px;
}

h1, h2 {
  font-family: $secondary-font;
}

a, button {
  &:hover:enabled {
    cursor: pointer;
  }
}

ul, li, a {
  text-decoration: none;
  list-style: none;
}

#pricespider, #service-center, #metronics, #react-challenges {
  padding: 2rem 4rem;
}

.tech-stack {
  max-width: 400px;
  h3 {
    font-weight: 400;
    margin-top: 0.8rem;
  }
}
.tech-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.6rem;
  //margin: 0.8rem 0 2rem 0;
}
.tech {
  background-color: $dark;
  color: $light;
  padding: 5px 8px;
  border-radius: 5px;
  font-size: 0.8rem;
}


@media screen and (max-width: 767px) {
  #pricespider, #service-center, #metronics, #react-challenges {
    padding: 1rem 2rem;
    margin: 0;
  }
}

@import 'home';
@import 'header';
@import 'project';
@import 'modal';