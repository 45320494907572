.image-modal {
  position: fixed;
  z-index: 4;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(20, 20, 20, 0.8);

  img {
    max-width: 700px;
    border: 3px solid $dark;
    border-radius: 10px !important;
  }
}

.modal-content {
  position: relative;
  max-width: 600px;
  max-height: 80vh;
  border-radius: 10px;
}

.close {
  font-size: 2rem;
  font-weight: bold;
  position: absolute;
  top: -3rem;
  right: -8rem;
  color: white;

  &:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 767px) {
  .image-modal {

    img {
      max-width: calc(100vw - 2rem);
    }

    .close {
      right: 1rem;

      &:hover {
        cursor: pointer;
      }
    }
  }
}