@import 'config';

#homepage {
  padding: 2rem 4rem;
  h1 {
    font-size: 2.8rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }
  h2 {
    font-size: 2.3rem;
  }
  p, em {
    font-size: 1.1rem;
  }
  strong {
    font-family: $secondary-font;
    font-size: 1.4rem;
  }
  [class*='col-'] {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .link {
    color: $dark;
    font-size: 1.1rem;
    font-style: italic;
    &:hover {
      color: $blue;
    }
  }
}

#bio {
  margin-bottom: 0;
  div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
}

#bio, #projects, #technologies {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 4rem;
  margin: 2rem 0;
  padding-bottom: 2rem;
}

#projects {
  padding-top: 1rem;
  border-top: 3px solid $red;
  h2 {
    color: $red;
    font-weight: 400;
  }
  .project-list {
    margin-top: 0.8rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .project-link {
    border-bottom: 1px solid $gray;
    padding-bottom: 0.6rem;
    h3 {
      font-weight: 400;
      font-size: 1.5rem;
    }
    p {
      font-size: 1rem;
    }
    &:hover {
      cursor: pointer;
      color: $blue;
    }
  }
}

#technologies {
  padding-top: 1rem;
  border-top: 3px solid $red;
  h2 {
    color: $red;
    font-weight: 400;
  }
  .tech-stack {
    max-width: 400px;
    h3 {
      font-weight: 400;
      margin-top: 0.8rem;
    }
  }
  .tech-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.6rem;
    margin: 0.8rem 0 2rem 0;
  }
  .tech {
    background-color: $dark;
    color: $light;
    padding: 5px 8px;
    border-radius: 5px;
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 767px) {
  #homepage {
    margin: 0;
    padding: 10px;
  }
  #bio, #projects, #technologies {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 2rem 1rem;
  }
  #projects, #technologies {
    gap: 1.5rem;
  }
  #technologies .tech-list {
    justify-content: center;
  }
}