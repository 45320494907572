$background: #FFF;
$dark: #000000;
$light: #F9F5EB;
$gray: #E4DCCF;
$red: #EA5455;
$blue: #0F6292;

$primary-font: 'Poppins', sans-serif;
$secondary-font: 'Inconsolata', monospace;

@mixin easeOut {
  transition: all .3s ease-out;
}